import React from 'react'
import Main from './Components/Main'

export default function App() {
  return (
    <div>
      <Main/>
    </div>
  )
}
